<template>
    <layout>
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSaveProductButtonClick"
        />
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="card-box">
            <div class="text-center">
                <router-link to="/products?type=other">
                    <button
                        type="button"
                        class="btn w-sm btn-light waves-effect mx-1"
                    >
                        Cancel
                    </button>
                </router-link>
                <a
                    v-if="product && editMode"
                    :href="previewUrl"
                    target="_blank"
                    class="btn btn-info mx-1"
                >
                    <i class="far fa-eye mr-1" />
                    Preview
                </a>
                <button
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light mx-1"
                    @click="onSaveProductButtonClick"
                >
                    <i class="fas fa-save mr-1" />
                    Save
                </button>
            </div>
        </div>

        <div class="card">
            <ul class="nav nav-pills navtab-bg nav-justified m-2">
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: activeTab === tab.value
                        }"
                        @click.prevent="activeTab = tab.value"
                    >
                        {{ tab.text }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-show="activeTab === 'product'">
            <div class="card-box">
                <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                </h5>
                <div class="form-group mb-3">
                    <label for="product-name">
                        Product Type
                        <span class="text-danger">*</span>
                    </label>
                    <b-form-select
                        v-model="product.type"
                        :class="{
                            'is-invalid': $v.product.type.$error
                        }"
                        :disabled="editMode"
                        :options="productTypes"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-name">
                        Product Name
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="product-name"
                        v-model="product.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.product.name.$error
                        }"
                        placeholder="Product Name"
                        @blur="onNameBlur"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-slug">
                        Slug
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="product-slug"
                        v-model="product.slug"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.product.slug.$error || slugExists
                        }"
                        placeholder="Product slug"
                        @blur="onSlugBlur"
                    />
                    <div v-if="slugExists" class="invalid-feedback">
                        Slug already exists.
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="product-description_title">
                        Product Description Headline
                    </label>
                    <input
                        id="product-description_title"
                        v-model="product.description_title"
                        type="text"
                        class="form-control"
                        placeholder="Product Description Headline"
                        rows="3"
                    />
                </div>
                <div
                    v-if="product.description || !editMode"
                    class="form-group mb-3"
                >
                    <wysiwyg-editor
                        v-model="product.description"
                        label="Product Description"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-skin_type">
                        Skin Type
                    </label>
                    <input
                        id="product-skin_type"
                        v-model="product.skin_type"
                        type="text"
                        class="form-control"
                        placeholder="Skin Type"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-volume">
                        Volume
                    </label>
                    <input
                        id="product-volume"
                        v-model="product.volume"
                        type="text"
                        class="form-control"
                        placeholder="Volume"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="product-patch">
                        Patch
                    </label>
                    <input
                        id="product-patch"
                        v-model="product.patch"
                        type="text"
                        class="form-control"
                        placeholder="Patch"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="sku">SKU Number</label>
                    <input
                        id="sku"
                        v-model="product.sku"
                        type="text"
                        class="form-control"
                        placeholder="Enter amount"
                    />
                </div>
                <div class="form-group mb-3">
                    <b-form-checkbox
                        v-model="product.out_of_stock"
                        name="Out of Stock"
                        switch
                    >
                        Out of Stock
                    </b-form-checkbox>
                </div>

                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Pricing
                </h5>
                <div class="row">
                    <div class="col-6 form-group mb-3">
                        <label for="product_price">
                            Price <span class="text-danger">*</span>
                        </label>
                        <currency-input
                            id="product_price"
                            v-model.number="product.price"
                            type="text"
                            class="form-control"
                            :class="{
                                'is-invalid': $v.product.price.$error
                            }"
                            placeholder="Enter amount"
                        />
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="sale_price">Sale Price </label>
                        <currency-input
                            id="sale_price"
                            v-model.number="product.sale_price"
                            class="form-control"
                            placeholder="Enter amount"
                        />
                    </div>
                </div>

                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Images
                </h5>
                <label class="ml-2 mb-0" for="upsell_image">
                    Images
                </label>
                <media-chooser
                    v-model="product.images"
                    multiple-mode
                    class="col-12"
                />
                <label class="ml-2 mb-0" for="upsell_image">
                    Upsell Image
                    <span class="text-danger">*</span>
                </label>
                <media-chooser
                    id="upsell_image"
                    v-model="product.upsell_image"
                    :error-border="$v.product.upsell_image.$error"
                    add-buff
                    class="col-12"
                />
                <label class="ml-2 mb-0" for="cart_image">
                    Cart Image
                    <span class="text-danger">*</span>
                </label>
                <media-chooser
                    id="cart_image"
                    v-model="product.cart_image"
                    :error-border="$v.product.cart_image.$error"
                    add-buff
                    class="col-12"
                />

                <product-video v-model="product.video" />
            </div>

            <product-benefits
                :key="product.id"
                v-model="product.benefits"
                class="card-box"
            />

            <div class="card-box">
                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Options
                </h5>

                <div class="form-group col-12 mt-1">
                    <label>Fedex Product Name</label>
                    <input
                        v-model="product.fedex_name"
                        class="form-control"
                    />
                </div>
            </div>
        </div>

        <div v-show="activeTab === 'shop'">
            <div class="card-box" style="margin-bottom: 150px">
                <div class="row m-0">
                    <h5 class="col-12 text-uppercase mt-0 mb-2 bg-light p-2">
                        Shop
                    </h5>
                    <media-chooser
                        v-model="product.shop_image"
                        add-buff
                        label="Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <media-chooser
                        v-model="product.shop_image_secondary"
                        add-buff
                        label="Mouseover Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <div class="form-group col-12">
                        <label>Name</label>
                        <textarea
                            v-model="product.shop_name"
                            class="form-control"
                            rows="1"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Description</label>
                        <textarea
                            v-model="product.shop_description"
                            class="form-control"
                            rows="1"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Tooltip</label>
                        <textarea
                            v-model="product.shop_tooltip"
                            class="form-control"
                            rows="2"
                        />
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        Patch
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="product.shop_patch.enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <div class="form-group col-4">
                        <label>Text</label>
                        <input
                            v-model="product.shop_patch.text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Text Color</label>
                        <color-picker
                            :key="product.id"
                            v-model="product.shop_patch.textColor"
                            :color="product.shop_patch.textColor"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Background Color</label>
                        <color-picker
                            :key="product.id"
                            v-model="product.shop_patch.backgroundColor"
                            :color="product.shop_patch.backgroundColor"
                        />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import config from '@config';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ProductDetailsMixin from './productDetails.mixin.js';
import ProductVideo from '@components/products/ProductVideo';
import WysiwygEditor from '@components/elements/WysiwygEditor';
import ProductBenefits from '@components/products/ProductBenefits';

export default {
    validations: {
        product: {
            type: {
                required
            },
            name: {
                required
            },
            slug: {
                required
            },
            price: {
                required
            },
            upsell_image: {
                required
            },
            cart_image: {
                required
            }
        }
    },

    components: {
        WysiwygEditor,
        ProductVideo,
        ProductBenefits
    },

    mixins: [ProductDetailsMixin],

    data() {
        return {
            pageTitle: `PRODUCT DETAIL`,
            tabs: [
                {
                    value: 'product',
                    text: 'Product Information'
                },
                {
                    value: 'shop',
                    text: 'Shop Information'
                }
            ],
            activeTab: 'product',
            product: {
                type: 'cream',
                shop_patch: {}
            },
            initValueStringify: '',
            productTypes: ['cream']
        };
    },

    computed: {
        previewUrl() {
            return `${config.frontendUrl}/cream-products/${this.product.slug}`;
        }
    },

    methods: {
        ...mapActions({
            findProduct: 'secondaryProducts/show',
            storeProduct: 'secondaryProducts/store',
            updateProduct: 'secondaryProducts/update'
        })
    }
};
</script>
